import React from 'react'
import loadable from '@loadable/component'
import { graphql } from 'gatsby'
import WpAcfHeaderprimaryBlock from '@modules/WpAcfHeaderprimaryBlock'
import WpAcfTeamoverviewBlock from '@modules/WpAcfTeamoverviewBlock'
import { strings } from '../static/strings'
import Footer from '@root/Footer'
import { useGTM } from '@hooks/useGTM'
import { replaceWithCorrectTranslation } from '../helpers/replaceWithCorrectTranslation'
import SEO from '../components/root/Seo'
import NavBar from '@root/NavBar'

export default function TeamTemplate({ data, path }) {
	const page = data.main
	const { locale } = page.locale
	const lang = locale.substr(0, 2)
	useGTM({ locale, path, pageType: page?.pageOptionsPageType?.pageType || 'team' })

	const headerFields = {
		formButton: true,
		formId: 9,
		image: page.postTypeTeam?.teamHeader.imagePrimary,
		imageMobile: page.postTypeTeam?.teamHeader.imageMobile || null,
		headline: page.postTypeTeam?.teamHeader.headline,
		text: page.postTypeTeam?.teamHeader.text,
		button: { title: strings[lang].teamFormButton },
	}

	const blocksJSON = JSON.parse(page.blocksJSON)
	return (
		<>
			<NavBar />
			<SEO seoData={page.seo} />
			<WpAcfHeaderprimaryBlock
				team={true}
				data={{ headerFields, carNr: page.postTypeTeam?.carNr }}
				locale={locale}
				overline={page.teamCategory?.nodes[0]?.name ?? page.postTypeTeam?.teaserFields?.overline}
			/>
			{page.blocks.map((module, index) => {
				if (module.__typename.includes('WpCore')) return
				replaceWithCorrectTranslation(module, blocksJSON)
				const LoadableModuleBlock = loadable(() => import(`@modules/${module.__typename}`))
				return <LoadableModuleBlock data={module} key={`${module.__typename}-${index}`} locale={locale} />
			})}
			<WpAcfTeamoverviewBlock data={{ modulesTeamOverview: { layout: 'small' } }} locale={locale} />
			<Footer />
		</>
	)
}

export const query = graphql`
	query ($post: String) {
		main: wpTeam(id: { eq: $post }) {
			title
			uri
			id
			locale {
				locale
			}
			blocks {
				__typename
				...AllBlocks
			}
			seo {
				metaDesc
				title
				opengraphImage {
					localFile {
						publicURL
					}
				}
				schema {
					pageType
				}
			}
			blocksJSON
			...PostTeamFragment
			pageOptionsPageType {
				pageType
			}
		}
	}
`
